import * as React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { DateTime } from "luxon";

interface DateInputProps {
    label: string;
    name: string;
    value: Date;
    handler: (e: React.FormEvent<HTMLInputElement>) => void;
}

const DateInput: React.FunctionComponent<DateInputProps> = ({
    label,
    name,
    value,
    handler
}) => (
    <FormGroup>
        <Label htmlFor={name}>{label}</Label>

        <Input
            type="date"
            id={name}
            name={name}
            value={value ? DateTime.local(value).toISODate() : undefined}
            onChange={handler}
        />
    </FormGroup>
);

export default DateInput;
