import * as React from "react";
import { FormGroup, Input, Label } from "reactstrap";

interface BooleanRadioProps {
    name: string;
    inline?: boolean;
    handler: (e: React.FormEvent<HTMLInputElement>, checked: boolean) => void;
    groupLabel: string;
    value: boolean;
}

const BooleanRadio: React.FunctionComponent<BooleanRadioProps> = ({
    name,
    handler,
    groupLabel,
    value
}) => (
    <FormGroup>
        <Label className="d-block">{groupLabel}</Label>

        <FormGroup check inline>
            <Label check>
                <Input
                    type="radio"
                    name={name}
                    checked={value}
                    value="Yes"
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handler(e, true)
                    }
                />
                Yes
            </Label>
        </FormGroup>

        <FormGroup check inline>
            <Label check>
                <Input
                    type="radio"
                    name={name}
                    checked={value !== undefined && !value}
                    value="No"
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handler(e, false)
                    }
                />
                No
            </Label>
        </FormGroup>
    </FormGroup>
);

export default BooleanRadio;
