import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button } from "reactstrap";

interface AddRemoveButtonsProps {
    collectionLength: number;
    currentIndex: number;
    addMessage: string;
    removeMessage: string;
    removeHandler: () => void;
    addHandler: () => void;
}

const AddRemoveButtons: React.FunctionComponent<AddRemoveButtonsProps> = ({
    collectionLength,
    currentIndex,
    addMessage,
    removeMessage,
    removeHandler,
    addHandler
}) => (
    <div className="text-right">
        {collectionLength > 1 && (
            <Button
                type="button"
                color="dark"
                size="sm"
                outline
                onClick={removeHandler}
                className={
                    currentIndex === collectionLength - 1 ? "mr-2" : null
                }
            >
                <FontAwesomeIcon icon={faMinus} className="mr-2" />
                {removeMessage}
            </Button>
        )}

        {currentIndex === collectionLength - 1 && (
            <Button
                type="button"
                color="dark"
                size="sm"
                onClick={addHandler}
                className={currentIndex === collectionLength ? "mr-2" : null}
            >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                {addMessage}
            </Button>
        )}
    </div>
);

export default AddRemoveButtons;
