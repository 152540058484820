import * as React from "react";
import { FormGroup, Input, Label } from "reactstrap";

interface SelectListProps {
    label: string;
    name: string;
    value: string;
    options: string[];
    handler: (e: React.FormEvent<HTMLInputElement>) => void;
}

const SelectList: React.FunctionComponent<SelectListProps> = ({
    label,
    name,
    value,
    options,
    handler
}) => (
    <FormGroup>
        <Label htmlFor={name}>{label}</Label>

        <Input
            type="select"
            name={name}
            id={name}
            className="form-control"
            onChange={handler}
            value={value}
        >
            <option value="">---</option>

            {options.map((option, i) => (
                <option key={`${name}-option-${i}`} value={option}>
                    {option}
                </option>
            ))}
        </Input>
    </FormGroup>
);

export default SelectList;
