import * as React from "react";
import { graphql, navigate } from "gatsby";
import { DateTime } from "luxon";
import {
    faLessThan,
    faMinus,
    faPaperPlane,
    faPlus,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import axios from "axios";
import ContentSection from "../../components/contentSection";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import {
    States,
    ReferralSources,
    ClearanceLevels,
    DegreeTypes,
    EmploymentTypes
} from "../../data";
import BooleanRadio from "../../components/careers/booleanRadio";
import StringRadio from "../../components/careers/stringRadio";
import SelectList from "../../components/careers/selectList";
import StringInput from "../../components/careers/stringInput";
import DateInput from "../../components/careers/dateInput";
import AddRemoveButtons from "../../components/careers/addRemoveButtons";
import { JobApplication } from "../../models";
import ValidationModal from "../../components/careers/validationModal";
import { FileUpload } from "../../models/JobApplication";

interface ApiResponse {
    title: string;
    message: string;
}

interface YamlNode {
    node: {
        title: string;
    };
}

interface ApplyProps {
    data: {
        allJobPostingsYaml: {
            edges: YamlNode[];
        };
    };
}

interface ModalContent {
    title: string;
    message: string;
}

interface ApplyState {
    application: JobApplication;
    resume?: File;
    coverLetter?: File;
    otherDocumentation?: File;
    submitInProgress: boolean;
    showModal: boolean;
    modalContent: ModalContent;
    validationErrors: string[];
}

class Apply extends React.Component<ApplyProps> {
    state: ApplyState = {
        application: {
            name: "",
            addressStreet: "",
            addressCity: "",
            addressState: "",
            addressZip: "",
            phone: "",
            email: "",
            referralSource: "",
            position: "",
            degrees: [
                {
                    schoolName: "",
                    location: "",
                    degreeName: ""
                }
            ],
            certifications: [
                {
                    certificationName: ""
                }
            ],
            militaryService: [
                {
                    branch: "",
                    rank: "",
                    mos: ""
                }
            ],
            workExperience: [{ employerName: "" }],
            references: [{ name: "" }],
            certifyCorrect: false,
            files: []
        },
        submitInProgress: false,
        showModal: false,
        modalContent: { title: "", message: "" },
        validationErrors: []
    };

    handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        const application = this.state.application;
        application.name = e.currentTarget.value;
        this.setState({ application });
    };

    handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const application = this.state.application;
        application[e.currentTarget.name] = e.currentTarget.value;
        this.setState({
            application
        });
    };

    handleEducationInputChange = (
        e: React.FormEvent<HTMLInputElement>,
        i: number
    ) => {
        const application = this.state.application;
        const degrees = application.degrees;
        const degree = degrees[i];

        const dashIndex = e.currentTarget.name.indexOf("-");
        const propName = e.currentTarget.name.substring(0, dashIndex);

        degree[propName] = e.currentTarget.value;
        degrees[i] = degree;
        application.degrees = degrees;

        this.setState({
            application
        });
    };

    handleCertificationInputChange = (
        e: React.FormEvent<HTMLInputElement>,
        i: number
    ) => {
        const application = this.state.application;
        const certifications = application.certifications;
        const cert = certifications[i];

        const dashIndex = e.currentTarget.name.indexOf("-");
        const propName = e.currentTarget.name.substring(0, dashIndex);

        cert[propName] = e.currentTarget.value;
        certifications[i] = cert;
        application.certifications = certifications;

        this.setState({
            application
        });
    };

    handleNestedInputChange = (
        e: React.FormEvent<HTMLInputElement>,
        i: number,
        nestedUnder: string
    ) => {
        const application = this.state.application;
        const items = application[nestedUnder];
        const item = items[i];

        const dashIndex = e.currentTarget.name.indexOf("-");
        const propName = e.currentTarget.name.substring(0, dashIndex);

        item[propName] = e.currentTarget.value;
        items[i] = item;
        application[nestedUnder] = items;

        this.setState({
            application
        });
    };

    handleBooleanRadioChange = (
        e: React.FormEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        const application = this.state.application;
        application[e.currentTarget.name] = checked;
        this.setState({
            application
        });
    };

    handleBooleanEducationChange = (
        e: React.FormEvent<HTMLInputElement>,
        i: number
    ) => {
        const application = this.state.application;
        const degrees = application.degrees;
        const degree = degrees[i];

        const dashIndex = e.currentTarget.name.indexOf("-");
        const propName = e.currentTarget.name.substring(0, dashIndex);

        degree[propName] = e.currentTarget.value === "Yes";
        degrees[i] = degree;
        application.degrees = degrees;

        this.setState({
            application
        });
    };

    handleBooleanCertificationChange = (
        e: React.FormEvent<HTMLInputElement>,
        i: number
    ) => {
        const application = this.state.application;
        const certifications = application.certifications;
        const cert = certifications[i];

        const dashIndex = e.currentTarget.name.indexOf("-");
        const propName = e.currentTarget.name.substring(0, dashIndex);

        cert[propName] = e.currentTarget.value === "Yes";
        certifications[i] = cert;
        application.certifications = certifications;

        this.setState({
            application
        });
    };

    handleNestedBooleanChange = (
        e: React.FormEvent<HTMLInputElement>,
        i: number,
        nestedUnder: string
    ) => {
        const application = this.state.application;
        const items = application[nestedUnder];
        const item = items[i];

        const dashIndex = e.currentTarget.name.indexOf("-");
        const propName = e.currentTarget.name.substring(0, dashIndex);

        item[propName] = e.currentTarget.value === "Yes";
        items[i] = item;
        application[nestedUnder] = items;

        this.setState({
            application
        });
    };

    handleDateChange = (e: React.FormEvent<HTMLInputElement>) => {
        const application = this.state.application;
        const date = new Date(Date.parse(e.currentTarget.value));
        application[e.currentTarget.name] = date;

        this.setState({ application });
    };

    handleNestedDateChange = (
        e: React.FormEvent<HTMLInputElement>,
        i: number,
        nestedUnder: string = "certifications"
    ) => {
        const application = this.state.application;
        const date = new Date(Date.parse(e.currentTarget.value));
        const items = application[nestedUnder];
        const item = items[i];

        const dashIndex = e.currentTarget.name.indexOf("-");
        const propName = e.currentTarget.name.substring(0, dashIndex);
        item[propName] = date;
        items[i] = item;
        application[nestedUnder] = items;

        this.setState({ application });
    };

    handleFileChange = async (e: React.FormEvent<HTMLInputElement>) => {
        const currentTarget = e.currentTarget;
        const files = currentTarget.files;
        const file = files[0];

        if (file === null) return;

        const fileContent = await this.getBase64(file);
        const { application } = this.state;

        let newFile: FileUpload = {
            content: fileContent.replace(/^data:.+;base64,/, ""),
            contentType: file.type,
            filename: file.name
        };

        application.files.push(newFile);

        if (JSON.stringify(application).length > 6000000) {
            this.setState({
                showModal: true,
                modalContent: {
                    title: "File Too Large",
                    message:
                        "Your application exceeds the maximum size. Please submit without this file and send all additional documentation to careers@tibercreek.com after you have submitted your application."
                }
            });
            currentTarget.value = null;
            application.files.pop();
            return;
        } else {
            this.setState({ [currentTarget.name]: file, application });
        }
    };

    handleCheckboxChange = (e: React.FormEvent<HTMLInputElement>) => {
        const application = this.state.application;
        application[e.currentTarget.name] = e.currentTarget.checked;
        this.setState({ application });
    };

    getBase64 = (f: File) =>
        new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(f);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });

    addError = async (errorMessage: string) => {
        let { validationErrors } = this.state;

        if (!validationErrors) {
            validationErrors = [];
        }

        validationErrors.push(errorMessage);
        validationErrors = Array.from(new Set(validationErrors));

        this.setState({ validationErrors });
    };

    validateApp = async () => {
        const { application, resume } = this.state;

        if (!application.name || application.name === "") {
            await this.addError("You must provide your name");
        }

        if (
            !application.addressStreet ||
            application.addressStreet === "" ||
            !application.addressCity ||
            application.addressCity === "" ||
            !application.addressState ||
            application.addressState === "" ||
            !application.addressZip ||
            application.addressZip === ""
        ) {
            await this.addError("You must provide your full address");
        }

        if (!application.phone || application.phone === "") {
            await this.addError("You must provide your phone number");
        }

        if (!application.email || application.email === "") {
            await this.addError("You must provide your email address");
        }

        if (!application.certifyCorrect) {
            await this.addError(
                "You must certify that your application is true, accurate, and complete"
            );
        }

        if (!application.position || application.position === "") {
            await this.addError("You must select a position to apply for");
        }

        if (application.isUsCitizen === undefined) {
            await this.addError(
                "You must indicate whether you are a US citizen"
            );
        }

        if (!resume) {
            await this.addError("You must upload a resume");
        }

        return this.state.validationErrors.length === 0;
    };

    toggleModal = () => {
        this.setState({
            showModal: false,
            modalContent: { title: "", message: "" },
            validationErrors: []
        });
    };

    handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isValid = await this.validateApp();

        if (!isValid) {
            this.setState({
                showModal: true,
                modalContent: {
                    title: "Application Errors"
                }
            });

            return;
        }

        this.setState({ submitInProgress: true });
        const endpoint = "/.netlify/functions/process-application";

        const { application } = this.state;

        axios
            .post<ApiResponse>(endpoint, application)
            .then(({ status, data: { title, message } }) => {
                if (status === 200) {
                    navigate("/careers/thank-you");
                } else {
                    this.setState({
                        showModal: true,
                        modalContent: { title, message }
                    });
                }
            })
            .catch(err => {
                this.setState({
                    showModal: true,
                    modalContent: {
                        title: "An Error Occurred",
                        message:
                            "An error occurred while attempting to process your application. Please contact careers@tibercreek.com directly."
                    }
                });
            })
            .finally(() => {
                this.setState({ submitInProgress: false });
            });
    };

    addDegree = () => {
        const application = this.state.application;
        const degrees = application.degrees;
        degrees.push({ schoolName: "", location: "", degreeName: "" });
        application.degrees = degrees;
        this.setState({ application });
    };

    removeDegree = (i: number) => {
        const application = this.state.application;
        const degrees = application.degrees;
        degrees.splice(i, 1);
        application.degrees = degrees;
        this.setState({ application });
    };

    addCert = () => {
        const application = this.state.application;
        const certifications = application.certifications;
        certifications.push({ certificationName: "" });
        application.certifications = certifications;
        this.setState({ application });
    };

    removeCert = (i: number) => {
        const application = this.state.application;
        const certifications = application.certifications;
        certifications.splice(i, 1);
        application.certifications = certifications;
        this.setState({ application });
    };

    addItem = (itemType: string, item: object) => {
        const application = this.state.application;
        const items = application[itemType];
        items.push(item);
        application[itemType] = items;
        this.setState({ application });
    };

    removeItem = (itemType: string, i: number) => {
        const application = this.state.application;
        const items = application[itemType];
        items.splice(i, 1);
        application[itemType] = items;
        this.setState({ application });
    };

    render() {
        const {
            application: {
                name,
                addressStreet,
                addressCity,
                addressState,
                addressZip,
                phone,
                email,
                referralSource,
                referralSourceOther,
                referralSourceDetails,
                position,
                positionOther,
                isUsCitizen,
                startDate,
                employmentType,
                currentEmploymentStatus,
                hasPreviouslyApplied,
                previousApplicationDate,
                hasSecurityClearance,
                clearanceLevel,
                clearanceLevelOther,
                locationsConsidered,
                isWillingToRelocate,
                salaryRange,
                degrees,
                certifications,
                hasMilitaryService,
                militaryService,
                workExperience,
                references,
                resume,
                coverLetter,
                otherDocumentation,
                certifyCorrect
            },
            submitInProgress
        } = this.state;

        const {
            data: {
                allJobPostingsYaml: { edges }
            }
        } = this.props;

        return (
            <Layout>
                <SEO title="Apply" />

                <ContentSection title="Apply to Tiber Creek">
                    <Row className="justify-content-center mb-4">
                        <Col lg={10} xl={8}>
                            <p>
                                <strong>
                                    Tiber Creek Consulting, Inc. is an Equal
                                    Opportunity Employer. We do not discriminate
                                    on the basis of race, color, religion,
                                    national origin, sex, gender identity or
                                    expression, sexual orientation, age, marital
                                    status, personal appearance, family
                                    responsibilities, genetic information,
                                    disability, matriculation, political
                                    affiliation, veteran status, or liability
                                    for service in the Armed forces of the
                                    United States. Tiber Creek Consulting, Inc.
                                    is also a Drug Free Workplace.
                                </strong>
                            </p>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col lg={8} xl={6}>
                            <Form
                                name="apply"
                                method="POST"
                                netlify="true"
                                netlify-honeypot="bot-field"
                                action="/careers/thank-you"
                                onSubmit={this.handleSubmit}
                            >
                                <input type="hidden" name="bot-field" />
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="apply"
                                />

                                <div id="applicant-info" className="mb-5">
                                    <h3 className="mb-3">
                                        Applicant Information
                                    </h3>
                                    <div className="form-group">
                                        <label htmlFor="name">Your Name</label>
                                        <Input
                                            id="name"
                                            name="name"
                                            className="form-control"
                                            value={name}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>

                                    <fieldset>
                                        <legend>Address</legend>

                                        <div className="form-group">
                                            <label htmlFor="addressStreet">
                                                Street
                                            </label>
                                            <Input
                                                id="addressStreet"
                                                name="addressStreet"
                                                className="form-control"
                                                value={addressStreet}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="addressCity">
                                                City
                                            </label>
                                            <Input
                                                id="addressCity"
                                                name="addressCity"
                                                className="form-control"
                                                value={addressCity}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="addressState">
                                                State
                                            </label>
                                            <Input
                                                type="select"
                                                name="addressState"
                                                id="addressState"
                                                className="form-control"
                                                value={addressState}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            >
                                                <option value={null}>
                                                    Select a state...
                                                </option>
                                                {States.map(state => (
                                                    <option
                                                        value={state.value}
                                                        key={state.value}
                                                    >
                                                        {state.text}
                                                    </option>
                                                ))}
                                            </Input>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="addressZip">
                                                ZIP Code
                                            </label>
                                            <Input
                                                name="addressZip"
                                                id="addressZip"
                                                className="form-control"
                                                value={addressZip}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                        </div>
                                    </fieldset>

                                    <fieldset>
                                        <legend>Contact</legend>

                                        <FormGroup>
                                            <Label htmlFor="phone">
                                                Phone Number
                                            </Label>
                                            <Input
                                                id="phone"
                                                name="phone"
                                                className="form-control"
                                                value={phone}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="email">
                                                Email Address
                                            </Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                className="form-control"
                                                value={email}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                        </FormGroup>
                                    </fieldset>

                                    <fieldset>
                                        <legend>Referral</legend>

                                        <FormGroup>
                                            <Label htmlFor="referralSource">
                                                How did you hear about Tiber
                                                Creek?
                                            </Label>

                                            <Input
                                                type="select"
                                                id="referralSource"
                                                name="referralSource"
                                                className="form-control"
                                                value={referralSource}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            >
                                                <option value="">
                                                    Select a source...
                                                </option>

                                                {ReferralSources.map(rs => (
                                                    <option key={rs}>
                                                        {rs}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>

                                        {referralSource &&
                                            referralSource.length > 0 &&
                                            (referralSource ===
                                                "Current or former Tiber Creek employee" ||
                                                referralSource ===
                                                    "Current or former Tiber Creek client" ||
                                                referralSource ===
                                                    "Posting on a job board") && (
                                                <FormGroup>
                                                    <Label htmlFor="referralSourceDetails">
                                                        {referralSource ===
                                                            "Current or former Tiber Creek employee" && (
                                                            <React.Fragment>
                                                                Employee Name
                                                            </React.Fragment>
                                                        )}

                                                        {referralSource ===
                                                            "Current or former Tiber Creek client" && (
                                                            <React.Fragment>
                                                                Client Name
                                                            </React.Fragment>
                                                        )}

                                                        {referralSource ===
                                                            "Posting on a job board" && (
                                                            <React.Fragment>
                                                                Job Board
                                                            </React.Fragment>
                                                        )}
                                                    </Label>

                                                    <Input
                                                        id="referralSourceDetails"
                                                        name="referralSourceDetails"
                                                        value={
                                                            referralSourceDetails
                                                        }
                                                        onChange={
                                                            this
                                                                .handleInputChange
                                                        }
                                                    />
                                                </FormGroup>
                                            )}

                                        {referralSource === "Other" && (
                                            <FormGroup>
                                                <Label htmlFor="referralSourceOther">
                                                    Source
                                                </Label>

                                                <Input
                                                    id="referralSourceOther"
                                                    name="referralSourceOther"
                                                    className="form-control"
                                                    value={referralSourceOther}
                                                    onChange={
                                                        this.handleInputChange
                                                    }
                                                />
                                            </FormGroup>
                                        )}
                                    </fieldset>
                                </div>

                                <div id="employment-desired" className="mb-5">
                                    <h3 className="mb-4">Employment Desired</h3>
                                    <FormGroup>
                                        <Label htmlFor="position">
                                            Position
                                        </Label>

                                        <Input
                                            type="select"
                                            id="position"
                                            name="position"
                                            className="form-control"
                                            value={position}
                                            onChange={this.handleInputChange}
                                        >
                                            <option value="">
                                                Select a position...
                                            </option>

                                            {edges &&
                                                edges.length > 0 &&
                                                edges.map(
                                                    ({ node: { title } }) => (
                                                        <option key={title}>
                                                            {title}
                                                        </option>
                                                    )
                                                )}

                                            <option value="other">Other</option>
                                        </Input>
                                    </FormGroup>

                                    {position === "other" && (
                                        <FormGroup>
                                            <Label htmlFor="positionOther">
                                                Other Position
                                            </Label>

                                            <Input
                                                id="positionOther"
                                                name="positionOther"
                                                className="form-control"
                                                value={positionOther}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                            />
                                        </FormGroup>
                                    )}

                                    <BooleanRadio
                                        name="isUsCitizen"
                                        groupLabel="Federal contracts require certain positions to be filled only by US citizens. Are you compliant with this requirement?"
                                        value={isUsCitizen}
                                        handler={this.handleBooleanRadioChange}
                                    />

                                    <FormGroup>
                                        <Label htmlFor="startDate">
                                            When can you start?
                                        </Label>

                                        <Input
                                            type="date"
                                            id="startDate"
                                            name="startDate"
                                            value={
                                                startDate
                                                    ? DateTime.local(
                                                          startDate
                                                      ).toISODate()
                                                    : undefined
                                            }
                                            onChange={this.handleDateChange}
                                            className="form-control"
                                        />
                                    </FormGroup>

                                    <StringRadio
                                        groupLabel="Are you looking for full or part time employment?"
                                        name="employmentType"
                                        value={employmentType}
                                        options={[
                                            "Full Time",
                                            "Part Time",
                                            "Full or Part Time"
                                        ]}
                                        handler={this.handleInputChange}
                                    />

                                    <StringRadio
                                        groupLabel="Are you currently employed?"
                                        name="currentEmploymentStatus"
                                        value={currentEmploymentStatus}
                                        options={[
                                            "Yes, Full Time",
                                            "Yes, Part Time",
                                            "No"
                                        ]}
                                        handler={this.handleInputChange}
                                    />

                                    <BooleanRadio
                                        name="hasPreviouslyApplied"
                                        handler={this.handleBooleanRadioChange}
                                        groupLabel="Have you ever applied to Tiber Creek Consulting before?"
                                        value={hasPreviouslyApplied}
                                    />

                                    {hasPreviouslyApplied && (
                                        <FormGroup>
                                            <Label htmlFor="previousApplicationDate">
                                                When did you apply? (MM/DD/YYYY)
                                            </Label>

                                            <Input
                                                type="date"
                                                id="previousApplicationDate"
                                                name="previousApplicationDate"
                                                value={
                                                    previousApplicationDate
                                                        ? DateTime.local(
                                                              startDate
                                                          ).toISODate()
                                                        : undefined
                                                }
                                                onChange={this.handleDateChange}
                                                className="form-control"
                                            />
                                        </FormGroup>
                                    )}

                                    <BooleanRadio
                                        name="hasSecurityClearance"
                                        handler={this.handleBooleanRadioChange}
                                        groupLabel="Do you have a security clearance?"
                                        value={hasSecurityClearance}
                                    />

                                    {hasSecurityClearance && (
                                        <SelectList
                                            label="What level is your clearance?"
                                            name="clearanceLevel"
                                            value={clearanceLevel}
                                            options={ClearanceLevels}
                                            handler={this.handleInputChange}
                                        />
                                    )}

                                    {hasSecurityClearance &&
                                        clearanceLevel === "Other" && (
                                            <StringInput
                                                label="Level"
                                                name="clearanceLevelOther"
                                                value={clearanceLevelOther}
                                                handler={this.handleInputChange}
                                            />
                                        )}

                                    <FormGroup>
                                        <Label htmlFor="locationsConsidered">
                                            Please indicate the employment
                                            locations you desire or would
                                            consider.
                                        </Label>

                                        <Input
                                            id="locationsConsidered"
                                            name="locationsConsidered"
                                            value={locationsConsidered}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <BooleanRadio
                                        name="isWillingToRelocate"
                                        handler={this.handleBooleanRadioChange}
                                        groupLabel="Are you willing to relocate?"
                                        value={isWillingToRelocate}
                                    />

                                    <FormGroup>
                                        <Label htmlFor="salaryRange">
                                            What salary range are you looking
                                            for?
                                        </Label>

                                        <Input
                                            id="salaryRange"
                                            name="salaryRange"
                                            value={salaryRange}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                </div>

                                <div id="education" className="mb-5">
                                    <h3 className="mb-4">
                                        Education and Professional
                                        Certifications
                                    </h3>

                                    <fieldset>
                                        <legend>Education</legend>

                                        {degrees &&
                                            degrees.map((degree, i) => (
                                                <React.Fragment
                                                    key={`degree-${i + 1}`}
                                                >
                                                    <h4 className="h6">
                                                        Degree {i + 1}
                                                    </h4>

                                                    <StringInput
                                                        name={`schoolName-${i}`}
                                                        label="School Name"
                                                        value={
                                                            degree.schoolName
                                                        }
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleEducationInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />

                                                    <StringInput
                                                        name={`location-${i}`}
                                                        label="Location"
                                                        value={degree.location}
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleEducationInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />

                                                    <SelectList
                                                        name={`degreeType-${i}`}
                                                        label="Degree Type"
                                                        value={
                                                            degree.degreeType
                                                        }
                                                        options={DegreeTypes}
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleEducationInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />

                                                    <BooleanRadio
                                                        groupLabel="Have you completed this degree?"
                                                        name={`isCompleted-${i}`}
                                                        value={
                                                            degree.isCompleted
                                                        }
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleBooleanEducationChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />

                                                    <StringInput
                                                        label="Degree Name/Area of Study"
                                                        name={`degreeName-${i}`}
                                                        value={
                                                            degree.degreeName
                                                        }
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleEducationInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />

                                                    {degree.isCompleted ===
                                                        false && (
                                                        <FormGroup>
                                                            <Label
                                                                htmlFor={`creditsEarned-${i}`}
                                                            >
                                                                Credit Hours
                                                                Earned
                                                            </Label>

                                                            <Input
                                                                type="number"
                                                                name={`creditsEarned-${i}`}
                                                                id={`creditsEarned-${i}`}
                                                                value={
                                                                    degree.creditsEarned
                                                                }
                                                                onChange={(
                                                                    e: React.FormEvent<
                                                                        HTMLInputElement
                                                                    >
                                                                ) =>
                                                                    this.handleEducationInputChange(
                                                                        e,
                                                                        i
                                                                    )
                                                                }
                                                            />
                                                        </FormGroup>
                                                    )}

                                                    <div className="text-right">
                                                        {degrees.length > 1 && (
                                                            <Button
                                                                type="button"
                                                                color="dark"
                                                                outline
                                                                size="sm"
                                                                onClick={() =>
                                                                    this.removeDegree(
                                                                        i
                                                                    )
                                                                }
                                                                className={
                                                                    i ===
                                                                        degrees.length -
                                                                            1 &&
                                                                    "mr-2"
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faMinus
                                                                    }
                                                                    size="sm"
                                                                    className="mr-2"
                                                                />
                                                                Remove Degree
                                                            </Button>
                                                        )}

                                                        {i ===
                                                            degrees.length -
                                                                1 && (
                                                            <Button
                                                                type="button"
                                                                color="dark"
                                                                size="sm"
                                                                onClick={
                                                                    this
                                                                        .addDegree
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPlus
                                                                    }
                                                                    size="sm"
                                                                    className="mr-2"
                                                                />
                                                                Add Another
                                                                Degree
                                                            </Button>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                    </fieldset>

                                    <fieldset>
                                        <legend>
                                            Professional Certifications
                                        </legend>

                                        <p className="form-text">
                                            Please note that proof of
                                            certifications is required prior to
                                            scheduling an interview.
                                        </p>

                                        {certifications &&
                                            certifications.map((cert, i) => (
                                                <React.Fragment
                                                    key={`certification-${
                                                        i + 1
                                                    }`}
                                                >
                                                    <h4 className="h6">
                                                        Certification {i + 1}
                                                    </h4>

                                                    <StringInput
                                                        name={`certificationName-${i}`}
                                                        value={
                                                            cert.certificationName
                                                        }
                                                        label="Certification Name"
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleCertificationInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />

                                                    <DateInput
                                                        name={`dateEarned-${i}`}
                                                        label="Date Earned (MM/DD/YYYY)"
                                                        value={cert.dateEarned}
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleNestedDateChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />

                                                    <DateInput
                                                        name={`dateValid-${i}`}
                                                        label="Date Valid Until (MM/DD/YYYY) (Optional)"
                                                        value={cert.dateEarned}
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleNestedDateChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />

                                                    <div className="text-right">
                                                        {certifications.length >
                                                            1 && (
                                                            <Button
                                                                color="dark"
                                                                type="button"
                                                                outline
                                                                size="sm"
                                                                className={
                                                                    i ===
                                                                        certifications.length -
                                                                            1 &&
                                                                    "mr-2"
                                                                }
                                                                onClick={() =>
                                                                    this.removeCert(
                                                                        i
                                                                    )
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faMinus
                                                                    }
                                                                    className="mr-2"
                                                                />
                                                                Remove
                                                                Certificate
                                                            </Button>
                                                        )}

                                                        {i ===
                                                            certifications.length -
                                                                1 && (
                                                            <Button
                                                                color="dark"
                                                                type="button"
                                                                size="sm"
                                                                onClick={
                                                                    this.addCert
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPlus
                                                                    }
                                                                    className="mr-2"
                                                                />
                                                                Add Certificate
                                                            </Button>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                    </fieldset>

                                    <fieldset>
                                        <legend>Military Service</legend>

                                        <BooleanRadio
                                            groupLabel="Do you have any US Military Service experience?"
                                            name="hasMilitaryService"
                                            value={hasMilitaryService}
                                            handler={
                                                this.handleBooleanRadioChange
                                            }
                                        />

                                        {hasMilitaryService &&
                                            militaryService.map((ms, i) => (
                                                <React.Fragment
                                                    key={`militaryService-${i}`}
                                                >
                                                    <h4 className="h6">
                                                        Military Service {i + 1}
                                                    </h4>

                                                    <DateInput
                                                        label="Start Date of Service (MM/DD/YYYY)"
                                                        name={`startDate-${i}`}
                                                        value={ms.startDate}
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleNestedDateChange(
                                                                e,
                                                                i,
                                                                "militaryService"
                                                            )
                                                        }
                                                    />

                                                    <DateInput
                                                        label="End Date of Service (MM/DD/YYYY) (Optional)"
                                                        name={`endDate-${i}`}
                                                        value={ms.endDate}
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleNestedDateChange(
                                                                e,
                                                                i,
                                                                "militaryService"
                                                            )
                                                        }
                                                    />

                                                    <StringInput
                                                        label="Branch of Service"
                                                        name={`branch-${i}`}
                                                        value={ms.branch}
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleNestedInputChange(
                                                                e,
                                                                i,
                                                                "militaryService"
                                                            )
                                                        }
                                                    />

                                                    <StringInput
                                                        label="Rank"
                                                        name={`rank-${i}`}
                                                        value={ms.rank}
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleNestedInputChange(
                                                                e,
                                                                i,
                                                                "militaryService"
                                                            )
                                                        }
                                                    />

                                                    <StringInput
                                                        label="Military Occupational Specialty (MOS) or Areas of Concentration (AOC)"
                                                        name={`mos-${i}`}
                                                        value={ms.mos}
                                                        handler={(
                                                            e: React.FormEvent<
                                                                HTMLInputElement
                                                            >
                                                        ) =>
                                                            this.handleNestedInputChange(
                                                                e,
                                                                i,
                                                                "militaryService"
                                                            )
                                                        }
                                                    />

                                                    <div className="text-right">
                                                        {militaryService.length >
                                                            1 && (
                                                            <Button
                                                                type="button"
                                                                color="dark"
                                                                size="sm"
                                                                outline
                                                                onClick={() =>
                                                                    this.removeItem(
                                                                        "militaryService",
                                                                        i
                                                                    )
                                                                }
                                                                className={
                                                                    i ===
                                                                        militaryService.length -
                                                                            1 &&
                                                                    "mr-2"
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faMinus
                                                                    }
                                                                    className="mr-2"
                                                                    size="sm"
                                                                />
                                                                Remove Service
                                                            </Button>
                                                        )}

                                                        {i ===
                                                            militaryService.length -
                                                                1 && (
                                                            <Button
                                                                type="button"
                                                                color="dark"
                                                                size="sm"
                                                                onClick={() =>
                                                                    this.addItem(
                                                                        "militaryService",
                                                                        {
                                                                            branch:
                                                                                "",
                                                                            rank:
                                                                                "",
                                                                            mos:
                                                                                ""
                                                                        }
                                                                    )
                                                                }
                                                                className={
                                                                    i ===
                                                                        militaryService.length -
                                                                            1 &&
                                                                    "mr-2"
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPlus
                                                                    }
                                                                    className="mr-2"
                                                                    size="sm"
                                                                />
                                                                Add Additional
                                                                Service
                                                            </Button>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                    </fieldset>
                                </div>

                                <div id="work-experience" className="mb-5">
                                    <h3 className="mb-4">Experience</h3>

                                    <fieldset>
                                        <legend>Work Experience</legend>

                                        <p className="form-text">
                                            Please list your last five
                                            employers, starting with your most
                                            recent employer.
                                        </p>

                                        <p className="form-text">
                                            For each employer, please list the
                                            employer, not the client, if
                                            applicable. If you would like to
                                            note that employers changed but
                                            client remained the same, please do
                                            so in your cover letter.
                                        </p>

                                        <p className="form-text">
                                            Include all employers (last five),
                                            even if employed in a different
                                            field of work than the one you are
                                            applying for.
                                        </p>

                                        <p className="form-text">
                                            All information will be verified
                                            prior to offers of employment
                                        </p>

                                        {workExperience.map((we, i) => (
                                            <React.Fragment
                                                key={`workExperience-${i}`}
                                            >
                                                <h4 className="h6">
                                                    Employer {i + 1}
                                                </h4>

                                                <StringInput
                                                    label="Employer Name"
                                                    name={`employerName-${i}`}
                                                    value={we.employerName}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedInputChange(
                                                            e,
                                                            i,
                                                            "workExperience"
                                                        )
                                                    }
                                                />

                                                <StringInput
                                                    label="Employer Location"
                                                    name={`employerLocation-${i}`}
                                                    value={we.employerLocation}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedInputChange(
                                                            e,
                                                            i,
                                                            "workExperience"
                                                        )
                                                    }
                                                />

                                                <StringInput
                                                    label="Phone Number"
                                                    name={`phone-${i}`}
                                                    value={we.phone}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedInputChange(
                                                            e,
                                                            i,
                                                            "workExperience"
                                                        )
                                                    }
                                                />

                                                <StringInput
                                                    label="Position Title"
                                                    name={`position-${i}`}
                                                    value={we.position}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedInputChange(
                                                            e,
                                                            i,
                                                            "workExperience"
                                                        )
                                                    }
                                                />

                                                <SelectList
                                                    label="Employment Type"
                                                    name={`employmentType-${i}`}
                                                    value={we.employmentType}
                                                    options={EmploymentTypes}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedInputChange(
                                                            e,
                                                            i,
                                                            "workExperience"
                                                        )
                                                    }
                                                />

                                                <DateInput
                                                    label="Start Date (MM/DD/YYYY)"
                                                    name={`startDate-${i}`}
                                                    value={we.startDate}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedDateChange(
                                                            e,
                                                            i,
                                                            "workExperience"
                                                        )
                                                    }
                                                />

                                                <BooleanRadio
                                                    groupLabel="Are you still employed here?"
                                                    name={`isStillEmployed-${i}`}
                                                    value={we.isStillEmployed}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedBooleanChange(
                                                            e,
                                                            i,
                                                            "workExperience"
                                                        )
                                                    }
                                                />

                                                {we.isStillEmployed ===
                                                    false && (
                                                    <React.Fragment>
                                                        <DateInput
                                                            label="End Date (MM/DD/YYYY)"
                                                            name={`endDate-${i}`}
                                                            value={we.endDate}
                                                            handler={(
                                                                e: React.FormEvent<
                                                                    HTMLInputElement
                                                                >
                                                            ) =>
                                                                this.handleNestedDateChange(
                                                                    e,
                                                                    i,
                                                                    "workExperience"
                                                                )
                                                            }
                                                        />

                                                        <StringInput
                                                            label="Reason for Leaving"
                                                            name={`reasonForLeaving-${i}`}
                                                            value={
                                                                we.reasonForLeaving
                                                            }
                                                            handler={(
                                                                e: React.FormEvent<
                                                                    HTMLInputElement
                                                                >
                                                            ) =>
                                                                this.handleNestedInputChange(
                                                                    e,
                                                                    i,
                                                                    "workExperience"
                                                                )
                                                            }
                                                        />
                                                    </React.Fragment>
                                                )}

                                                <AddRemoveButtons
                                                    collectionLength={
                                                        workExperience.length
                                                    }
                                                    currentIndex={i}
                                                    addMessage="Add Another Employer"
                                                    removeMessage="Remove Employer"
                                                    addHandler={() =>
                                                        this.addItem(
                                                            "workExperience",
                                                            { employerName: "" }
                                                        )
                                                    }
                                                    removeHandler={() =>
                                                        this.removeItem(
                                                            "workExperience",
                                                            i
                                                        )
                                                    }
                                                />
                                            </React.Fragment>
                                        ))}
                                    </fieldset>

                                    <fieldset>
                                        <legend>Professional References</legend>

                                        <p className="form-text">
                                            Please provide information for three
                                            people not related to you whom you
                                            have known for at least one year and
                                            who can attest to your professional
                                            qualifications.
                                        </p>

                                        {references.map((reference, i) => (
                                            <React.Fragment
                                                key={`references-${i}`}
                                            >
                                                <h4 className="h6">
                                                    Reference {i + 1}
                                                </h4>

                                                <StringInput
                                                    label="Name"
                                                    name={`name-${i}`}
                                                    value={reference.name}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedInputChange(
                                                            e,
                                                            i,
                                                            "references"
                                                        )
                                                    }
                                                />

                                                <StringInput
                                                    label="Email Address"
                                                    name={`email-${i}`}
                                                    value={reference.email}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedInputChange(
                                                            e,
                                                            i,
                                                            "references"
                                                        )
                                                    }
                                                />

                                                <StringInput
                                                    label="Phone Number"
                                                    name={`phone-${i}`}
                                                    value={reference.phone}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedInputChange(
                                                            e,
                                                            i,
                                                            "references"
                                                        )
                                                    }
                                                />

                                                <StringInput
                                                    label="Relationship/Company Name"
                                                    name={`relationship-${i}`}
                                                    value={
                                                        reference.relationship
                                                    }
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedInputChange(
                                                            e,
                                                            i,
                                                            "references"
                                                        )
                                                    }
                                                />

                                                <StringInput
                                                    label="Years Known"
                                                    name={`yearsKnown-${i}`}
                                                    value={reference.yearsKnown}
                                                    handler={(
                                                        e: React.FormEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) =>
                                                        this.handleNestedInputChange(
                                                            e,
                                                            i,
                                                            "references"
                                                        )
                                                    }
                                                />

                                                <AddRemoveButtons
                                                    collectionLength={
                                                        references.length
                                                    }
                                                    currentIndex={i}
                                                    addMessage="Add Another Reference"
                                                    removeMessage="Remove Reference"
                                                    addHandler={() =>
                                                        this.addItem(
                                                            "references",
                                                            { name: "" }
                                                        )
                                                    }
                                                    removeHandler={() =>
                                                        this.removeItem(
                                                            "references",
                                                            i
                                                        )
                                                    }
                                                />
                                            </React.Fragment>
                                        ))}
                                    </fieldset>
                                </div>

                                <div id="documents" className="mb-5">
                                    <h3 className="mb-4">
                                        Résumé and Cover Letter
                                    </h3>

                                    <FormGroup>
                                        <Label for="resume">Résumé</Label>
                                        <Input
                                            type="file"
                                            name="resume"
                                            id="resume"
                                            onChange={this.handleFileChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="coverLetter">
                                            Cover Letter (Optional)
                                        </Label>
                                        <Input
                                            type="file"
                                            name="coverLetter"
                                            id="coverLetter"
                                            onChange={this.handleFileChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="otherDocumentation">
                                            Other Documentation (Optional)
                                        </Label>
                                        <Input
                                            type="file"
                                            name="otherDocumentation"
                                            id="otherDocumentation"
                                            onChange={this.handleFileChange}
                                        />
                                        <FormText className="text-muted">
                                            If you have additional documentation
                                            that you'd like to provide, such as
                                            additional certification
                                            information, please upload it here.
                                        </FormText>
                                    </FormGroup>
                                </div>

                                <div id="certification">
                                    <h3 className="mb-4">Certification</h3>
                                    <FormGroup>
                                        <Label>
                                            I hereby certify that all entries on
                                            and attachments to my application
                                            for employment are true, accurate,
                                            and complete. I agree and understand
                                            that any falsification of
                                            information, regardless of time of
                                            discovery, may result in rescinding
                                            a job offer if offered a job or
                                            termination of employment if I
                                            become employed by Tiber Creek
                                            Consulting, Inc.
                                        </Label>

                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    name="certifyCorrect"
                                                    checked={certifyCorrect}
                                                    onChange={
                                                        this
                                                            .handleCheckboxChange
                                                    }
                                                />{" "}
                                                Agree
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </div>

                                <div className="text-center mt-4">
                                    <hr />
                                    <Button
                                        type="submit"
                                        className="btn btn-lg px-5 btn-primary"
                                        disabled={
                                            !certifyCorrect || submitInProgress
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                submitInProgress
                                                    ? faSpinner
                                                    : faPaperPlane
                                            }
                                            pulse={submitInProgress}
                                            className="mr-3"
                                        />
                                        {submitInProgress ? "Sending" : "Send"}
                                    </Button>
                                </div>
                            </Form>

                            <ValidationModal
                                show={this.state.showModal}
                                title={this.state.modalContent.title}
                                message={this.state.modalContent.message}
                                errors={this.state.validationErrors}
                                toggle={this.toggleModal}
                            />
                        </Col>
                    </Row>
                </ContentSection>
            </Layout>
        );
    }
}

export default Apply;

export const pageQuery = graphql`
    {
        allJobPostingsYaml(filter: { current: { eq: true } }) {
            edges {
                node {
                    title
                }
            }
        }
    }
`;
