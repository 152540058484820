import * as React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface ValidationModalProps {
    show: boolean;
    title: string;
    message: string;
    errors: string[];
    toggle: () => void;
}

const ValidationModal: React.FunctionComponent<ValidationModalProps> = ({
    show,
    title,
    message,
    errors,
    toggle
}) => (
    <Modal isOpen={show} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>

        <ModalBody>
            <div className="my-2">
                {errors.length === 0 &&
                    message.length === 0 &&
                    "Your application could not be processed as submitted."}

                {errors.length === 0 && message.length > 0 && message}

                {errors.length > 0 && (
                    <ul className="mb-0">
                        {errors.map((err, i) => (
                            <li key={`error-${i}`}>{err}</li>
                        ))}
                    </ul>
                )}
            </div>
        </ModalBody>
    </Modal>
);

export default ValidationModal;
