const DegreeTypes = [
    "High School",
    "Associate's",
    "Bachelor's",
    "Master's",
    "Doctorate",
    "Other"
];

export default DegreeTypes;
