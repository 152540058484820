import * as React from "react";
import { FormGroup, Input, Label } from "reactstrap";

interface StringInputProps {
    label: string;
    name: string;
    value: string;
    handler: (e: React.FormEvent<HTMLInputElement>) => void;
}

const StringInput: React.FunctionComponent<StringInputProps> = ({
    label,
    name,
    value,
    handler
}) => (
    <FormGroup>
        <Label htmlFor={name}>{label}</Label>

        <Input
            id={name}
            name={name}
            value={value}
            className="form-control"
            onChange={handler}
        />
    </FormGroup>
);

export default StringInput;
