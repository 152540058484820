import * as React from "react";
import { FormGroup, Label, Input } from "reactstrap";

interface StringRadioProps {
    groupLabel: string;
    name: string;
    value: string;
    options: string[];
    handler: (e: React.FormEvent<HTMLInputElement>) => void;
}

const StringRadio: React.FunctionComponent<StringRadioProps> = ({
    groupLabel,
    name,
    value,
    options,
    handler
}) => (
    <FormGroup>
        <Label className="d-block">{groupLabel}</Label>
        {options.map(option => (
            <FormGroup check key={option}>
                <Label check>
                    <Input
                        type="radio"
                        name={name}
                        checked={value === option}
                        value={option}
                        onChange={handler}
                    />
                    {option}
                </Label>
            </FormGroup>
        ))}
    </FormGroup>
);

export default StringRadio;
